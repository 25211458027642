import Vapor from 'laravel-vapor';

// @ts-ignore
Vapor.withBaseAssetUrl(import.meta.env.VITE_VAPOR_ASSET_URL);

const assetUrl = (url: string): string => {
	if (url.startsWith('/')) {
		return Vapor.asset(url.substr(1));
	}
	return Vapor.asset(url);
};

export default assetUrl;
