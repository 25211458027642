import {useCallback, useEffect, useState} from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars -- it is used
import axios, {AxiosError, AxiosResponse} from 'axios';

export type PuddleBag<TModel, TRequest> = {
	isSaving: boolean;
	reset: () => void;
	model?: TModel & {id?: number | string};
	setModel?: (model: TModel & {id?: number | string}) => void;
	errors?: Record<keyof TRequest, string[]>;
	updateField: (field: keyof TRequest, value: string | number | unknown) => void;
	save: (mode?: 'store' | 'update') => Promise<boolean | (TModel & {id?: number | string})>;
	validateField: (field: keyof TRequest, value: string | number | unknown) => Promise<void>;
	fieldHasError: (field: keyof TRequest) => boolean;
	getFieldError: (field: keyof TRequest) => string | undefined;
};

interface PuddleFormProps<TModel, TRequest, TResponse = TModel> {
	initialValues?: Partial<TModel> & {id?: number | string};
	storeFunction?: (
		request: TRequest,
		validationOnly?: boolean,
		fieldToValidate?: string,
	) => Promise<AxiosResponse<TResponse>>;
	updateFunction?: (
		id: string | number,
		request: TRequest,
		validationOnly?: boolean,
		fieldToValidate?: string,
	) => Promise<AxiosResponse<TResponse>>;
	transformFunction?: (model: TModel & {id?: number | string}) => TRequest;
}

const usePuddleForm = <TModel, TRequest, TResponse = TModel>(
	{
		initialValues,
		storeFunction,
		updateFunction,
		transformFunction = (model) => model as unknown as TRequest,
	}: PuddleFormProps<TModel, TRequest, TResponse>,
	dependencies: Array<unknown> = [],
): PuddleBag<TModel, TRequest> => {
	const [isSaving, setIsSaving] = useState(false);
	const [model, setModel] = useState(
		initialValues as (TModel & {id?: number | string}) | undefined,
	);
	const [errors, setErrors] = useState<Record<keyof TRequest, string[]>>();

	const reset = () => {
		setModel(initialValues as TModel & {id?: number | string});
		setErrors(undefined);
	};

	const updateField = (field: keyof TRequest, value: string | number | unknown) => {
		if (model) {
			setModel({...model, [field]: value});
		} else {
			setModel({[field]: value} as TModel & {id: number | string});
		}
	};

	const save = async (
		mode?: 'store' | 'update',
	): Promise<boolean | (TModel & {id?: number | string})> => {
		if (!model) throw new Error('No model to save');

		setIsSaving(true);
		try {
			if (mode === 'store' && storeFunction) {
				const reply = await storeFunction(transformFunction(model));
				setModel(reply.data as TModel & {id: number | string});
				return reply.data as TModel & {id: number | string};
			}

			if (mode === 'update' && updateFunction) {
				const reply = await updateFunction(model.id!, transformFunction(model));
				setModel(reply.data as TModel & {id: number | string});
				return reply.data as TModel & {id: number | string};
			}

			// If mode is not specified, we'll try to update if the model has an id, otherwise we'll store it
			const reply =
				model?.id && updateFunction
					? await updateFunction(model.id, transformFunction(model))
					: await storeFunction!(transformFunction(model));
			setModel(reply.data as TModel & {id: number | string});

			return reply.data as TModel & {id: number | string};
		} catch (e: unknown | AxiosError) {
			if (axios.isAxiosError(e)) {
				setErrors(e.response?.data?.errors);
			} else {
				// eslint-disable-next-line no-console
				console.error(e);
			}
		} finally {
			setIsSaving(false);
		}

		return false;
	};

	const validateField = async (field: keyof TRequest, value: string | number | unknown) => {
		try {
			if (model && model.id && updateFunction) {
				await updateFunction(model.id, {[field]: value} as TRequest, true, String(field));
			} else if (storeFunction) {
				await storeFunction({[field]: value} as TRequest, true, String(field));
			}

			if (errors && field in errors) {
				const newErrors = {...errors};
				delete newErrors[field];
				setErrors(newErrors);
			}
		} catch (e: unknown | AxiosError) {
			if (axios.isAxiosError(e)) {
				if (errors) {
					setErrors({
						...errors,
						[field]: e.response?.data?.errors[field],
					});
				} else {
					setErrors(e.response?.data?.errors);
				}
			} else {
				// eslint-disable-next-line no-console
				console.error(e);
			}
		}
	};

	const fieldHasError = useCallback(
		(field: keyof TRequest) => !!errors && field in errors,
		[errors],
	);

	const getFieldError = useCallback(
		(field: keyof TRequest) => {
			try {
				return errors && field in errors ? errors[field].join(', ') : undefined;
			} catch (e) {
				return undefined;
			}
		},
		[errors],
	);

	useEffect(() => {
		setModel(initialValues as TModel & {id?: number | string});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, dependencies);

	return {
		isSaving,
		model,
		setModel,
		errors,
		updateField,
		save,
		validateField,
		fieldHasError,
		getFieldError,
		reset,
	};
};

export default usePuddleForm;
