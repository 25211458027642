import React from 'react';
import {
	FormControl,
	FormErrorMessage,
	FormLabel,
	Icon,
	Input,
	InputProps,
	List,
	ListItem,
	Tooltip,
} from '@chakra-ui/react';
import {MdInfo} from 'react-icons/md';
import {PuddleBag} from '../../hooks/usePuddleForm';

interface PuddleInputProps<TModel, TRequest> {
	label: string;
	type?: 'text' | 'email' | 'password' | 'number' | 'tel' | 'url';
	helperText?: string;
	fieldName: keyof TRequest;
	form: PuddleBag<TModel, TRequest>;
	isRequired?: boolean;
	inputProps?: InputProps;
	disabled?: boolean;
	onAfterChange?: (value: string) => void;
}

const PuddleInput = <TModel, TRequest>({
	label,
	fieldName,
	type = 'text',
	helperText,
	form,
	isRequired = false,
	inputProps = {},
	disabled = false,
	onAfterChange = () => {},
}: PuddleInputProps<TModel, TRequest>) => {
	const value =
		form.model && form.model[fieldName as unknown as keyof TModel]
			? (form.model[fieldName as unknown as keyof TModel] as string)
			: '';

	return (
		<FormControl isInvalid={form.fieldHasError(fieldName)} isRequired={isRequired}>
			<FormLabel color='gray.500'>
				{label}
				{helperText && (
					<Tooltip label={helperText}>
						<span>
							<Icon as={MdInfo} ml={2} />
						</span>
					</Tooltip>
				)}
			</FormLabel>
			<Input
				bgColor={isRequired && !value ? 'yellow.100' : 'white'}
				type={type}
				value={value}
				onChange={(e) => {
					form.updateField(fieldName, e.target.value);
					onAfterChange(e.target.value);
				}}
				onBlur={(e) => form.validateField(fieldName, e.target.value)}
				{...inputProps}
				disabled={disabled}
			/>
			{form.fieldHasError(fieldName) && (
				<FormErrorMessage fontSize='xs'>
					<List>
						{form.errors?.[fieldName]?.map((error) => (
							<ListItem key={error}>{error}</ListItem>
						))}
					</List>
				</FormErrorMessage>
			)}
		</FormControl>
	);
};

export default PuddleInput;
