import React from 'react';
import * as Sentry from '@sentry/react';
import {createRoot} from 'react-dom/client';

Sentry.init({
	// @ts-ignore
	enabled: process.env.NODE_ENV !== 'development',
	dsn: 'https://1986e06052a5de1024768e427499c294@o1280785.ingest.us.sentry.io/4506906995130368',
	integrations: [
		Sentry.browserTracingIntegration(),
		Sentry.replayIntegration({
			maskAllText: false,
			blockAllMedia: false,
		}),
	],
	// Performance Monitoring
	tracesSampleRate: 1.0, //  Capture 100% of the transactions
	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const createAppWithSentry = (containerId: string, app: React.ReactNode) => {
	const container = document.getElementById(containerId);
	const root = createRoot(container!);
	root.render(app);
};

export default createAppWithSentry;
