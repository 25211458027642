import {useQuery, useQueryClient} from '@tanstack/react-query';
import {Puddleglum} from '../puddleglum';

const useMe = () => {
	const queryClient = useQueryClient();
	const {data, error, isLoading} = useQuery(
		['me'],
		async () => {
			const response = await Puddleglum.Controllers.UserController.me();
			return response.data;
		},
		{
			refetchOnWindowFocus: false,
			retryOnMount: false,
			retry: false,
		},
	);
	const refetchMe = () => {
		queryClient.invalidateQueries(['me']);
	};

	return {me: data, refetchMe, error, isLoading};
};

export default useMe;
