import {formatInTimeZone} from 'date-fns-tz';
import format from 'date-fns/format';

// eslint-disable-next-line import/prefer-default-export
export const USDollar = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD',
});

export const AppointmentDate = (date?: Date, timezone?: string): string | undefined => {
	const tz = timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;
	return date ? formatInTimeZone(date, tz, 'MM/dd/yy hh:mm a zzz') : undefined;
};

export const getCurrentTimezone = (): string => Intl.DateTimeFormat().resolvedOptions().timeZone;

export const HumanFriendlyDate = AppointmentDate;

export const OrderDate = (date?: Date): string | undefined =>
	date ? format(date, 'MM/dd/yy') : undefined;

export const MysqlDate = (date?: Date): string | undefined =>
	date ? format(date, 'yyyy-MM-dd') : undefined;
