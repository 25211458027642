import React, {FC} from 'react';
import {
	Tooltip,
	Portal,
	Popover,
	PopoverTrigger,
	PopoverContent,
	PopoverBody,
	useMediaQuery,
} from '@chakra-ui/react';

interface HoverProps {
	children: string | JSX.Element | JSX.Element[];
	label: string;
	'aria-label'?: string;
}

const TouchableHover: FC<HoverProps> = ({children, label, 'aria-label': ariaLabel}) => {
	const [isLargerThan800] = useMediaQuery('(min-width: 800px)');

	return isLargerThan800 ? (
		<Tooltip label={label} aria-label={ariaLabel}>
			{children}
		</Tooltip>
	) : (
		<Popover trigger='click'>
			<PopoverTrigger>{children}</PopoverTrigger>
			<Portal>
				<PopoverContent bg='gray.600' color='white'>
					<PopoverBody fontSize='sm'>{label}</PopoverBody>
				</PopoverContent>
			</Portal>
		</Popover>
	);
};

export default TouchableHover;
