/**
 * This file is auto generated using 'php artisan puddleglum:generate'
 *
 * Changes to this file will be lost when the command is run again
 */
// eslint-disable-next-line max-classes-per-file
import axios, {AxiosRequestConfig} from 'axios';

function transformToQueryString(params: Record<string, any>): string {
	return Object.entries(params)
		.filter(([, value]) => value !== null && value !== undefined)
		.map(([key, value]) => {
			if (Array.isArray(value)) {
				return value
					.map(
						(arrayItem) =>
							`${encodeURIComponent(key)}[]=${encodeURIComponent(arrayItem)}`,
					)
					.join('&');
			}
			return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
		})
		.join('&');
}

export type PaginatedResponse<T> = {
	current_page: number;
	data: T[];
	from: number;
	last_page: number;
	last_page_url: string | null;
	links: Array<{url: string | null; label: string; active: boolean}>;
	next_page_url: string | null;
	per_page: number;
	prev_page_url: string | null;
	to: number;
	total: number;
};
export namespace Puddleglum.Models {
	export interface DrugIngredient {
		id: number;
		created_at: string | null;
		updated_at: string | null;
		deleted_at: string | null;
		drug_id: number;
		name: string;
		strength: string | null;
	}

	export interface SymptomSearch {
		id: number;
		created_at: string | null;
		updated_at: string | null;
		deleted_at: string | null;
		search: string;
		results: string;
	}

	export interface TreatmentGroup {
		id: number;
		created_at: string | null;
		updated_at: string | null;
		deleted_at: string | null;
		name: string;
		description: string | null;
		default_price: number | null;
		treatments?: Array<Puddleglum.Models.Treatment & {pivot: {[key: string]: any}}> | null;
		prices?: Array<Puddleglum.Models.Price> | null;
		organizations?: Array<
			Puddleglum.Models.Organization & {pivot: {[key: string]: any}}
		> | null;
		locations?: Array<Puddleglum.Models.Location & {pivot: {[key: string]: any}}> | null;
		treatments_count?: number | null;
		prices_count?: number | null;
		organizations_count?: number | null;
		locations_count?: number | null;
	}

	export interface BookingRequest {
		id: number;
		created_at: string | null;
		updated_at: string | null;
		deleted_at: string | null;
		user_id: number;
		organization_id: number;
		location_id: number | null;
		provider_id: number | null;
		status: string;
		requested_timeframe: string | null;
		type: string;
		reason: string | null;
		notes: string | null;
		balance: number;
		payment_status: string;
		starts_at: string | null;
		updated_by: number | null;
		original_order_id: number | null;
		needs_review: boolean;
		review_status: string | null;
		cancelled_at: string | null;
		cancel_reason: string | null;
		user?: Puddleglum.Models.User | null;
		provider?: Puddleglum.Models.Provider | null;
		organization?: Puddleglum.Models.Organization | null;
		location?: Puddleglum.Models.Location | null;
		original_order?: Puddleglum.Models.Order | null;
		orders?: Array<Puddleglum.Models.Order> | null;
		orders_count?: number | null;
	}

	export interface SupportRequestResponse {
		id: number;
		created_at: string | null;
		updated_at: string | null;
		deleted_at: string | null;
		support_request_id: number;
		user_id: number;
		response: string;
		user?: Puddleglum.Models.User | null;
		issue?: Puddleglum.Models.SupportRequest | null;
		assignees?: Array<Puddleglum.Models.User & {pivot: {[key: string]: any}}> | null;
		assignees_count?: number | null;
	}

	export interface SpecialistSearch {
		id: number;
		created_at: string | null;
		updated_at: string | null;
		deleted_at: string | null;
		search: string;
		results: string;
	}

	export interface Diagnosis {
		id: number;
		created_at: string | null;
		updated_at: string | null;
		deleted_at: string | null;
		effective_at: string | null;
		expires_at: string | null;
		diagnosis: string;
		diagnosis_code: string;
		diagnosis_code_system: string;
		treatments?: Array<Puddleglum.Models.Treatment & {pivot: {[key: string]: any}}> | null;
		treatments_count?: number | null;
	}

	export interface Order {
		id: number;
		created_at: string | null;
		updated_at: string | null;
		deleted_at: string | null;
		treatment_id: number | null;
		treatment_group_id: number | null;
		drug_id: number | null;
		drug_route_id: number | null;
		drug_packaging_id: number | null;
		organization_id: number | null;
		provider_id: number | null;
		pharmacy_id: number | null;
		user_id: number;
		booking_request_id: number | null;
		future_time_value: number;
		future_time_unit: string;
		estimated_completion_date: string | null;
		actual_completion_date: string | null;
		status: string;
		notes: string | null;
		billing_notes: string | null;
		billing_statement_id: number | null;
		price: number | null;
		employer_price: number | null;
		employee_price: number | null;
		treatment?: Puddleglum.Models.Treatment | null;
		treatment_group?: Puddleglum.Models.TreatmentGroup | null;
		drug?: Puddleglum.Models.Drug | null;
		organization?: Puddleglum.Models.Organization | null;
		provider?: Puddleglum.Models.Provider | null;
		pharmacy?: Puddleglum.Models.Provider | null;
		user?: Puddleglum.Models.User | null;
		booking_request?: Puddleglum.Models.BookingRequest | null;
		billing_statement?: Puddleglum.Models.BillingStatement | null;
	}

	export interface ZipCode {
		id: number;
		created_at: string | null;
		updated_at: string | null;
		zip_code: string;
		latitude: string;
		longitude: string;
	}

	export interface CoverageBucket {
		id: number;
		created_at: string | null;
		updated_at: string | null;
		deleted_at: string | null;
		name: string;
		description: string | null;
		treatments?: Array<Puddleglum.Models.Treatment & {pivot: {[key: string]: any}}> | null;
		treatment_groups?: Array<
			Puddleglum.Models.TreatmentGroup & {pivot: {[key: string]: any}}
		> | null;
		treatments_count?: number | null;
		treatment_groups_count?: number | null;
	}

	export interface Audit {
		id: number;
		created_at: string | null;
		updated_at: string | null;
		patient_id: number | null;
		description: string | null;
		employee_user_id: number | null;
		specialty_id: number | null;
		provider_id: number | null;
		coverage_bucket_id: number | null;
		permission_id: number | null;
		pharmacy_id: number | null;
		employer_id: number | null;
		organization_id: number | null;
		plan_id: number | null;
		location_id: number | null;
		price_id: number | null;
		treatment_id: number | null;
		order_id: number | null;
		orders?: Puddleglum.Models.Order | null;
		treatments?: Puddleglum.Models.Treatment | null;
		patient?: Puddleglum.Models.User | null;
		employee?: Puddleglum.Models.User | null;
		specialty?: Puddleglum.Models.Specialty | null;
		provider?: Puddleglum.Models.Provider | null;
		coverage_bucket?: Puddleglum.Models.CoverageBucket | null;
		permission?: Puddleglum.Models.Permission | null;
		pharmacy?: Puddleglum.Models.Pharmacy | null;
		employer?: Puddleglum.Models.Employer | null;
		organization?: Puddleglum.Models.Organization | null;
		plan?: Puddleglum.Models.Plan | null;
		location?: Puddleglum.Models.Location | null;
		price?: Puddleglum.Models.Price | null;
	}

	export interface DrugClass {
		id: number;
		created_at: string | null;
		updated_at: string | null;
		deleted_at: string | null;
		drug_id: number;
		name: string;
	}

	export interface Specialty {
		id: number;
		created_at: string | null;
		updated_at: string | null;
		deleted_at: string | null;
		name: string;
		is_preferred: boolean;
		description: string | null;
		providers?: Array<Puddleglum.Models.Provider & {pivot: {[key: string]: any}}> | null;
		providers_count?: number | null;
	}

	export interface User {
		id: number;
		employer_id: number | null;
		email: string;
		personal_email: string | null;
		email_verified_at: string | null;
		is_needing_onboarding: boolean;
		password: string;
		terms_accepted_at: string | null;
		verification_code: string | null;
		first_name: string | null;
		last_name: string | null;
		home_phone: string | null;
		cell_phone: string | null;
		work_phone: string | null;
		street_address1: string | null;
		street_address2: string | null;
		city: string | null;
		state: string | null;
		zip: string | null;
		date_of_birth: string | null;
		sex: string | null;
		gender: string | null;
		race: string | null;
		ethnicity: string | null;
		organization_id: number | null;
		pharmacy_id: number | null;
		starts_on: string | null;
		enrollment_status: string | null;
		plan_id: number | null;
		confirmation_code: string | null;
		confirmation_code_expires_at: string | null;
		remember_device_token: string | null;
		remember_device_token_expires_at: string | null;
		last_logged_in_at: string | null;
		failed_login_attempts: number;
		remember_token: string | null;
		created_at: string | null;
		updated_at: string | null;
		deleted_at: string | null;
		inactivated_at: string | null;
		stripe_id: string | null;
		pm_type: string | null;
		pm_last_four: string | null;
		trial_ends_at: string | null;
		is_primary_benefit_holder: boolean;
		is_dependent: boolean;
		subscriber_id: number | null;
		unenrolled_date: string | null;
		is_temporary_password: boolean;
		temporary_password_expires_at: string | null;
		is_accessing_as_admin: boolean;
		member_id: number | null;
		external_mrn: string | null;
		sms_consented_at: string | null;
		longitude: number | null;
		latitude: number | null;
		employer?: Puddleglum.Models.Employer | null;
		roles?: Array<Puddleglum.Models.UserRole & {pivot: {[key: string]: any}}> | null;
		plan?: Puddleglum.Models.Plan | null;
		organization?: Puddleglum.Models.Organization | null;
		pharmacy?: Puddleglum.Models.Pharmacy | null;
		booking_requests?: Array<Puddleglum.Models.BookingRequest> | null;
		orders?: Array<Puddleglum.Models.Order> | null;
		support_requests?: Array<
			Puddleglum.Models.SupportRequest & {pivot: {[key: string]: any}}
		> | null;
		billing_statements?: Array<Puddleglum.Models.BillingStatement> | null;
		dependents?: Array<Puddleglum.Models.User> | null;
		subscriber?: Puddleglum.Models.User | null;
		devices?: Array<Puddleglum.Models.UserDevice> | null;
		roles_count?: number | null;
		booking_requests_count?: number | null;
		orders_count?: number | null;
		support_requests_count?: number | null;
		billing_statements_count?: number | null;
		dependents_count?: number | null;
		devices_count?: number | null;
	}

	export interface Drug {
		id: number;
		created_at: string | null;
		updated_at: string | null;
		deleted_at: string | null;
		product_ndc: string;
		generic_name: string | null;
		labeler_name: string | null;
		dea_schedule: string | null;
		brand_name: string | null;
		finished: boolean;
		listing_expiration_date: string | null;
		marketing_category: string | null;
		dosage_form: string | null;
		spl_id: string | null;
		product_type: string | null;
		marketing_start_date: string | null;
		product_id: string | null;
		application_number: string | null;
		brand_name_base: string | null;
		aliases?: Array<Puddleglum.Models.DrugAlias> | null;
		classes?: Array<Puddleglum.Models.DrugClass> | null;
		ingredients?: Array<Puddleglum.Models.DrugIngredient> | null;
		packagings?: Array<Puddleglum.Models.DrugPackaging> | null;
		routes?: Array<Puddleglum.Models.DrugRoute> | null;
		aliases_count?: number | null;
		classes_count?: number | null;
		ingredients_count?: number | null;
		packagings_count?: number | null;
		routes_count?: number | null;
	}

	export interface Payment {
		id: number;
		created_at: string | null;
		updated_at: string | null;
		deleted_at: string | null;
		organization_id: number;
		user_id: number | null;
		employer_id: number | null;
		billing_statement_id: number;
		amount: number;
		paid_at: string | null;
		payment_intent_id: string | null;
		stripe_customer_id: string | null;
		stripe_payment_method_id: string | null;
		stripe_payment_method_type: string | null;
		billing_statement?: Puddleglum.Models.BillingStatement | null;
		user?: Puddleglum.Models.User | null;
		organization?: Puddleglum.Models.Organization | null;
	}

	export interface BillingStatement {
		id: number;
		created_at: string | null;
		updated_at: string | null;
		deleted_at: string | null;
		booking_request_id: number | null;
		organization_id: number | null;
		pharmacy_id: number | null;
		user_id: number;
		employer_id: number;
		price: number;
		payout_amount: number | null;
		is_locked: boolean;
		locked_at: string | null;
		has_been_transferred_by_platform: boolean;
		transferred_by_platform_at: string | null;
		has_been_paid_by_platform: boolean;
		paid_by_platform_at: string | null;
		has_been_reversed: boolean;
		reversed_at: string | null;
		employer_price: number;
		employer_balance: number | null;
		employer_last_paid_at: string | null;
		employee_price: number;
		employee_balance: number | null;
		employee_last_paid_at: string | null;
		payment_intent_id: string | null;
		stripe_secret_hash: string | null;
		status: string;
		stripe_provider_transfer_id: string | null;
		stripe_provider_payout_id: string | null;
		stripe_employer_payment_id: string | null;
		stripe_employee_payment_id: string | null;
		stripe_invoice_item_id: string | null;
		employer_invoice_id: number | null;
		organization?: Puddleglum.Models.Organization | null;
		employer?: Puddleglum.Models.Employer | null;
		pharmacy?: Puddleglum.Models.Pharmacy | null;
		orders?: Array<Puddleglum.Models.Order> | null;
		payments?: Array<Puddleglum.Models.Payment> | null;
		user?: Puddleglum.Models.User | null;
		employer_invoice?: Puddleglum.Models.EmployerInvoice | null;
		booking_request?: Puddleglum.Models.BookingRequest | null;
		orders_count?: number | null;
		payments_count?: number | null;
	}

	export interface DrugAlias {
		id: number;
		created_at: string | null;
		updated_at: string | null;
		deleted_at: string | null;
		drug_id: number;
		value: string;
		type: string;
	}

	export interface Price {
		id: number;
		created_at: string | null;
		updated_at: string | null;
		deleted_at: string | null;
		organization_id: number | null;
		pharmacy_id: number | null;
		treatment_id: number | null;
		treatment_group_id: number | null;
		drug_id: number | null;
		price: number;
		description: string | null;
		drug_packaging_id: number | null;
		treatment?: Puddleglum.Models.Treatment | null;
		treatment_group?: Puddleglum.Models.TreatmentGroup | null;
		drug?: Puddleglum.Models.Drug | null;
		organization?: Puddleglum.Models.Organization | null;
		pharmacy?: Puddleglum.Models.Pharmacy | null;
		drug_packaging?: Puddleglum.Models.DrugPackaging | null;
	}

	export interface Employer {
		id: number;
		name: string;
		email: string | null;
		phone: string | null;
		website: string | null;
		street_address1: string | null;
		street_address2: string | null;
		city: string | null;
		state: string | null;
		zip: string | null;
		plan_id: number | null;
		stripe_customer_id: string | null;
		is_stripe_onboarding_complete: boolean;
		created_at: string | null;
		updated_at: string | null;
		deleted_at: string | null;
		price_review_threshold: number;
		is_dependents_enabled: boolean;
		length_of_cobra_coverage: number | null;
		credit_balance: number;
		group_id: number | null;
		users?: Array<Puddleglum.Models.User> | null;
		plan?: Puddleglum.Models.Plan | null;
		histories?: Array<Puddleglum.Models.EmployerPlanHistory> | null;
		users_count?: number | null;
		histories_count?: number | null;
	}

	export interface PlanCoverageBucket {
		id: number;
		created_at: string | null;
		updated_at: string | null;
		deleted_at: string | null;
		plan_id: number;
		coverage_bucket_id: number;
		covered_pct: number;
		plan?: Puddleglum.Models.Plan | null;
		coverage_bucket?: Puddleglum.Models.CoverageBucket | null;
	}

	export interface UserRolePermission {
		id: number;
		user_role_id: number;
		permission_id: number;
		created_at: string | null;
		updated_at: string | null;
		deleted_at: string | null;
		can_edit: boolean;
		can_read: boolean;
		roles?: Array<Puddleglum.Models.UserRole & {pivot: {[key: string]: any}}> | null;
		permissions?: Array<Puddleglum.Models.Permission & {pivot: {[key: string]: any}}> | null;
		roles_count?: number | null;
		permissions_count?: number | null;
	}

	export interface Location {
		id: number;
		created_at: string | null;
		updated_at: string | null;
		deleted_at: string | null;
		organization_id: number;
		name: string;
		street_address1: string;
		street_address2: string | null;
		city: string;
		state: string;
		zip: string;
		phone: string;
		fax: string | null;
		website: string | null;
		photo_url: string | null;
		is_primary: boolean;
		timezone: string | null;
		is_searchable: boolean;
		longitude: number | null;
		latitude: number | null;
		organizations?: Puddleglum.Models.Organization | null;
		providers?: Array<Puddleglum.Models.Provider & {pivot: {[key: string]: any}}> | null;
		providers_count?: number | null;
	}

	export interface Pharmacy {
		id: number;
		created_at: string | null;
		updated_at: string | null;
		deleted_at: string | null;
		name: string;
		email: string;
		phone: string;
		fax: string | null;
		street_address1: string;
		street_address2: string | null;
		city: string;
		state: string;
		zip: string;
		website: string | null;
		photo_url: string | null;
		stripe_account_id: string | null;
		is_stripe_onboarding_complete: boolean;
	}

	export interface EmployerInvoice {
		id: number;
		created_at: string | null;
		updated_at: string | null;
		deleted_at: string | null;
		employer_id: number;
		amount: number;
		stripe_invoice_id: string | null;
		status: string | null;
		employer?: Puddleglum.Models.Employer | null;
		billing_statements?: Array<Puddleglum.Models.BillingStatement> | null;
		billing_statements_count?: number | null;
	}

	export interface Provider {
		id: number;
		created_at: string | null;
		updated_at: string | null;
		deleted_at: string | null;
		name: string;
		email: string | null;
		phone: string;
		street_address1: string;
		street_address2: string | null;
		city: string;
		state: string;
		zip: string;
		npi: string;
		website: string | null;
		photo_url: string | null;
		organizations?: Array<
			Puddleglum.Models.Organization & {pivot: {[key: string]: any}}
		> | null;
		specialties?: Array<Puddleglum.Models.Specialty & {pivot: {[key: string]: any}}> | null;
		plans?: Array<Puddleglum.Models.Plan & {pivot: {[key: string]: any}}> | null;
		locations?: Array<Puddleglum.Models.Location & {pivot: {[key: string]: any}}> | null;
		organizations_count?: number | null;
		specialties_count?: number | null;
		plans_count?: number | null;
		locations_count?: number | null;
	}

	export interface EmployerPlanHistory {
		id: number;
		employer_id: number;
		plan_id: number | null;
		created_at: string | null;
		updated_at: string | null;
		deleted_at: string | null;
	}

	export interface UserRole {
		id: number;
		name: string;
		description: string | null;
		created_at: string | null;
		updated_at: string | null;
		deleted_at: string | null;
		users?: Array<Puddleglum.Models.User & {pivot: {[key: string]: any}}> | null;
		permissions?: Array<Puddleglum.Models.Permission & {pivot: {[key: string]: any}}> | null;
		users_count?: number | null;
		permissions_count?: number | null;
	}

	export interface SupportRequestAssignment {
		id: number;
		created_at: string | null;
		updated_at: string | null;
		deleted_at: string | null;
		support_request_id: number;
		user_id: number;
		assigned_by: number | null;
		assigned_at: string | null;
		assignee?: Puddleglum.Models.User | null;
		users?: Array<Puddleglum.Models.User & {pivot: {[key: string]: any}}> | null;
		issue?: Puddleglum.Models.SupportRequest | null;
		users_count?: number | null;
	}

	export interface SupportRequest {
		id: number;
		created_at: string | null;
		updated_at: string | null;
		deleted_at: string | null;
		user_id: number;
		employer_id: number | null;
		organization_id: number | null;
		pharmacy_id: number | null;
		contact_email: string;
		subject: string;
		message_text: string;
		status: string;
		priority: string;
		resolved_by: number | null;
		resolved_at: string | null;
		user?: Puddleglum.Models.User | null;
		responses?: Array<Puddleglum.Models.SupportRequestResponse> | null;
		assignees?: Array<Puddleglum.Models.User & {pivot: {[key: string]: any}}> | null;
		organization?: Puddleglum.Models.Organization | null;
		pharmacy?: Puddleglum.Models.Pharmacy | null;
		employer?: Puddleglum.Models.Employer | null;
		responses_count?: number | null;
		assignees_count?: number | null;
	}

	export interface Permission {
		id: number;
		name: string;
		created_at: string | null;
		updated_at: string | null;
		deleted_at: string | null;
		roles?: Array<Puddleglum.Models.UserRole & {pivot: {[key: string]: any}}> | null;
		roles_count?: number | null;
	}

	export interface Plan {
		id: number;
		created_at: string | null;
		updated_at: string | null;
		deleted_at: string | null;
		name: string;
		description: string;
		deductible: number;
		out_of_pocket_max: number;
		providers?: Array<Puddleglum.Models.Provider & {pivot: {[key: string]: any}}> | null;
		coverage_buckets?: Array<
			Puddleglum.Models.CoverageBucket & {pivot: {[key: string]: any}}
		> | null;
		providers_count?: number | null;
		coverage_buckets_count?: number | null;
	}

	export interface Organization {
		id: number;
		created_at: string | null;
		updated_at: string | null;
		deleted_at: string | null;
		name: string;
		email: string;
		phone: string;
		fax: string | null;
		street_address1: string;
		street_address2: string | null;
		city: string;
		state: string;
		zip: string;
		website: string | null;
		photo_url: string | null;
		stripe_account_id: string | null;
		is_stripe_onboarding_complete: boolean;
		channels_api_key: string | null;
		locations?: Array<Puddleglum.Models.Location> | null;
		treatments?: Array<Puddleglum.Models.Treatment> | null;
		treatment_groups?: Array<Puddleglum.Models.TreatmentGroup> | null;
		providers?: Array<Puddleglum.Models.Provider & {pivot: {[key: string]: any}}> | null;
		prices?: Array<Puddleglum.Models.Price> | null;
		users?: Array<Puddleglum.Models.User> | null;
		locations_count?: number | null;
		treatments_count?: number | null;
		treatment_groups_count?: number | null;
		providers_count?: number | null;
		prices_count?: number | null;
		users_count?: number | null;
	}

	export interface UserDevice {
		id: number;
		user_id: number;
		expo_push_token: string;
		device_name: string;
		device_type: string;
		created_at: string | null;
		updated_at: string | null;
		user?: Puddleglum.Models.User | null;
	}

	export interface Treatment {
		id: number;
		created_at: string | null;
		updated_at: string | null;
		deleted_at: string | null;
		effective_at: string | null;
		expires_at: string | null;
		treatment: string;
		treatment_code: string;
		treatment_code_system: string;
		is_evaluation_and_maintenance: boolean;
		is_billable: boolean;
		default_price: number | null;
		keywords: string | null;
		frequency_limit: number | null;
		frequency_timeframe: string | null;
		diagnoses?: Array<Puddleglum.Models.Diagnosis & {pivot: {[key: string]: any}}> | null;
		organizations?: Array<
			Puddleglum.Models.Organization & {pivot: {[key: string]: any}}
		> | null;
		prices?: Array<Puddleglum.Models.Price> | null;
		buckets?: Array<Puddleglum.Models.CoverageBucket & {pivot: {[key: string]: any}}> | null;
		groups?: Array<Puddleglum.Models.TreatmentGroup & {pivot: {[key: string]: any}}> | null;
		locations?: Array<Puddleglum.Models.Location & {pivot: {[key: string]: any}}> | null;
		diagnoses_count?: number | null;
		organizations_count?: number | null;
		prices_count?: number | null;
		buckets_count?: number | null;
		groups_count?: number | null;
		locations_count?: number | null;
	}

	export interface DrugPackaging {
		id: number;
		created_at: string | null;
		updated_at: string | null;
		deleted_at: string | null;
		drug_id: number;
		package_ndc: string | null;
		description: string | null;
		marketing_start_date: string | null;
		sample: boolean;
	}

	export interface DrugRoute {
		id: number;
		created_at: string | null;
		updated_at: string | null;
		deleted_at: string | null;
		drug_id: number;
		name: string;
	}
}
export namespace Puddleglum.Requests.Pharmacy {
	export interface SearchUserRequest {
		name?: string | null;
	}

	export interface ProcessPricesCsv {
		csv_path: string;
	}

	export interface AddDrugOrder {
		user_id: number;
		drug_id: number;
		drug_route_id: number;
		drug_packaging_id: number;
		status: string;
		future_time_value?: number | null;
		future_time_unit?: string | null;
		estimated_completion_date?: string | null;
		actual_completion_date?: string | null;
		notes?: string | null;
	}

	export interface AddPrice {
		drug_id: number;
	}

	export interface UpdatePharmacy {
		name: string;
		email?: string | null;
		phone: string;
		fax?: string | null;
		street_address1: string;
		street_address2?: string | null;
		city: string;
		state: string;
		zip: string;
		website?: string | null;
		photo_url?: string | null;
	}

	export interface SearchOrdersRequest {
		user_id?: number | null;
		provider_id?: number | null;
		treatment_id?: number | null;
		status?: string | null;
		start_date?: string | null;
		end_date?: string | null;
	}

	export interface ProcessNdcPricesCsv {
		csv_path: string;
	}
}
export namespace Puddleglum.Requests {
	export interface AcknowledgeUpload {
		url: string;
		folder?: string | null;
	}

	export interface AddSupportRequest {
		subject: string;
		message_text: string;
		priority?: string | null;
		status?: string | null;
		type?: string | null;
		assignees?: any | null;
	}
}
export namespace Puddleglum.Requests.Auth {
	export interface UserPassword {
		password: string;
	}

	export interface ChangePassword {
		current_password: string;
		new_password: string;
		new_password_confirmation: string;
	}

	export interface UserLookupEmail {
		email: string;
	}

	export interface UserVerifyEmail {
		email: string;
		verification_code: string;
	}

	export interface UserResetPassword {
		email: string;
		confirmation_code: string;
		password: string;
	}

	export interface UserRegister {
		first_name: string;
		last_name: string;
		password: string;
		confirm_password?: string | null;
		is_primary_benefit_holder?: boolean | null;
		cell_phone: string;
		home_phone?: string | null;
		work_phone?: string | null;
		street_address1: string;
		street_address2?: string | null;
		city: string;
		state: string;
		zip: string;
		date_of_birth: string;
		sex: string;
		gender?: string | null;
		race?: string | null;
		ethnicity?: string | null;
		personal_email?: string | null;
		dependents?: Array<{
			first_name: string;
			last_name: string;
			date_of_birth: string;
			email?: string | null;
			cell_phone?: string | null;
		}> | null;
	}

	export interface UserLogin {
		email: string;
		password: string;
		remember_device_token?: string | null;
		is_resending?: boolean | null;
	}

	export interface UserForgotPassword {
		email: string;
	}
}
export namespace Puddleglum.Requests.Employer {
	export interface EditEmployee {
		first_name?: string | null;
		last_name?: string | null;
		email?: string | null;
		personal_email?: string | null;
		starts_on?: string | null;
		cell_phone?: string | null;
		home_phone?: string | null;
		work_phone?: string | null;
		street_address1?: string | null;
		street_address2?: string | null;
		city?: string | null;
		state?: string | null;
		zip?: string | null;
		date_of_birth?: string | null;
		sex?: string | null;
		gender?: string | null;
		race?: string | null;
		ethnicity?: string | null;
		enrollment_status?: string | null;
		unenrolled_date?: string | null;
	}

	export interface ProcessCsv {
		csv_path: string;
	}

	export interface AddEmployee {
		first_name: string;
		last_name: string;
		email: string;
		personal_email?: string | null;
		starts_on: string;
		enrollment_status?: string | null;
		is_primary_benefit_holder?: boolean | null;
		is_dependent?: boolean | null;
		subscriber_id?: number | null;
		unenrolled_date?: string | null;
	}

	export interface UpdateEmployer {
		name: string;
		email?: string | null;
		phone: string;
		street_address1: string;
		street_address2?: string | null;
		city: string;
		state: string;
		zip: string;
		website: string;
	}
}
export namespace Puddleglum.Requests.Admin {
	export interface AddTreatment {
		effective_at: string;
		expires_at?: string | null;
		treatment: string;
		treatment_code: string;
		treatment_code_system: string;
		is_evaluation_and_maintenance?: boolean | null;
		is_billable?: boolean | null;
		keywords?: string | null;
		default_price: number;
		frequency_limit?: number | null;
		frequency_timeframe?: string | null;
	}

	export interface StartJobRequest {
		job: string;
	}

	export interface AddCoverageBucket {
		name: string;
		description?: string | null;
		treatments?: any | null;
		treatment_groups?: any | null;
	}

	export interface AddTreatmentGroup {
		name: string;
		description?: string | null;
		default_price?: number | null;
		treatments?: any | null;
	}

	export interface AddOrganization {
		name: string;
		email: string;
		phone: string;
		fax?: string | null;
		street_address1: string;
		street_address2?: string | null;
		city: string;
		state: string;
		zip: string;
		website?: string | null;
		photo_url?: string | null;
		channels_api_key?: string | null;
	}

	export interface EditUser {
		first_name?: string | null;
		last_name?: string | null;
		email: string;
		personal_email?: string | null;
		starts_on?: string | null;
		cell_phone?: string | null;
		home_phone?: string | null;
		work_phone?: string | null;
		street_address1?: string | null;
		street_address2?: string | null;
		city?: string | null;
		state?: string | null;
		zip?: string | null;
		date_of_birth?: string | null;
		sex?: string | null;
		gender?: string | null;
		race?: string | null;
		ethnicity?: string | null;
		enrollment_status?: string | null;
		organization_id?: number | null;
		employer_id?: number | null;
		external_mrn?: string | null;
		roles?: any | null;
	}

	export interface AddPlan {
		name: string;
		description: string;
		deductible: number;
		out_of_pocket_max: number;
	}

	export interface AddPermission {
		name: string;
	}

	export interface ProcessHcpcsPricesCsv {
		csv_path: string;
	}

	export interface AddSupportRequestAssignment {
		support_request_id: number;
		assignees?: any | null;
	}

	export interface AddEmployer {
		name: string;
		email: string;
		phone?: string | null;
		street_address1?: string | null;
		street_address2?: string | null;
		city?: string | null;
		state?: string | null;
		zip?: string | null;
		plan_id?: number | null;
		price_review_threshold?: number | null;
		is_dependents_enabled?: boolean | null;
		length_of_cobra_coverage?: number | null;
	}

	export interface AddPrice {
		treatment_id?: number | null;
		treatment_group_id?: number | null;
		drug_id?: number | null;
		price: number;
		organization_id?: number | null;
		pharmacy_id?: number | null;
	}

	export interface AddSpecialty {
		name: string;
		is_preferred?: boolean;
	}

	export interface AddPharmacy {
		name: string;
		email?: string | null;
		phone: string;
		fax?: string | null;
		street_address1: string;
		street_address2?: string | null;
		city: string;
		state: string;
		zip: string;
		website?: string | null;
		photo_url?: string | null;
	}

	export interface AddProvider {
		name: string;
		phone: string;
		email?: string | null;
		street_address1: string;
		street_address2?: string | null;
		city: string;
		state: string;
		zip: string;
		npi?: string | null;
		website?: string | null;
		photo_url?: string | null;
		specialties?: any | null;
	}

	export interface AddUser {
		first_name: string;
		last_name: string;
		email: string;
		personal_email?: string | null;
		starts_on?: string | null;
		cell_phone?: string | null;
		home_phone?: string | null;
		work_phone?: string | null;
		street_address1?: string | null;
		street_address2?: string | null;
		city?: string | null;
		state?: string | null;
		zip?: string | null;
		date_of_birth?: string | null;
		sex?: string | null;
		gender?: string | null;
		race?: string | null;
		ethnicity?: string | null;
		enrollment_status?: string | null;
		organization_id?: number | null;
		employer_id?: number | null;
		external_mrn?: string | null;
		roles?: any | null;
	}

	export interface AddUserRole {
		name: string;
		permissions: Array<{
			permission_id?: number | null;
			can_edit?: boolean | null;
			can_read?: boolean | null;
		}>;
	}

	export interface ProcessChargeMasterPricesCsv {
		csv_path: string;
		organization_id?: number | null;
	}

	export interface UpdatePrice {
		price: number;
	}

	export interface AddSupportRequestResponse {
		response: string;
		support_request_id: number;
	}
}
export namespace Puddleglum.Requests.Provider {
	export interface SearchBookingRequests {
		user_id?: number | null;
		provider_id?: number | null;
		start_date?: string | null;
		end_date?: string | null;
		status?: string | null;
		timezone?: string | null;
	}

	export interface ProcessProviderCsv {
		csv_path: string;
	}

	export interface AddOrUpdateBookingRequest {
		location_id: number;
		provider_id?: number | null;
		status: string;
		starts_at?: string;
		notes?: string | null;
		cancel_reason?: string | null;
	}

	export interface AddOrder {
		user_id: number;
		provider_id: number;
		treatment_id: number;
		booking_request_id?: number | null;
		status: string;
		future_time_value?: number | null;
		future_time_unit?: string | null;
		estimated_completion_date?: string | null;
		actual_completion_date?: string | null;
		notes?: string | null;
	}

	export interface SearchUserRequest {
		name: string;
	}

	export interface ProcessPricesCsv {
		csv_path: string;
	}

	export interface UpdateOrganization {
		name: string;
		email?: string | null;
		phone: string;
		fax?: string | null;
		street_address1: string;
		street_address2?: string | null;
		city: string;
		state: string;
		zip: string;
		website?: string | null;
		photo_url?: string | null;
	}

	export interface AddPrice {
		treatment_id?: number | null;
		treatment_group_id?: number | null;
		price?: number;
		location_ids?: any | null;
	}

	export interface AddLocation {
		name: string;
		street_address1: string;
		street_address2?: string | null;
		city: string;
		state: string;
		zip: string;
		phone: string;
		fax?: string | null;
		website?: string | null;
		photo_url?: string | null;
		is_primary?: boolean | null;
		timezone?: string | null;
		is_searchable?: boolean | null;
	}

	export interface SearchOrdersRequest {
		user_id?: number | null;
		booking_request_id?: number | null;
		provider_id?: number | null;
		treatment_id?: number | null;
		status?: string | null;
		start_date?: string | null;
		end_date?: string | null;
	}

	export interface AddProvider {
		name: string;
		phone: string;
		email?: string | null;
		street_address1: string;
		street_address2?: string | null;
		city: string;
		state: string;
		zip: string;
		npi?: string | null;
		website?: string | null;
		photo_url?: string | null;
		locations?: any | null;
		specialties?: any | null;
	}
}
export namespace Puddleglum.Requests.Employee {
	export interface EditMyDependent {
		first_name?: string | null;
		last_name?: string | null;
		cell_phone?: string | null;
		home_phone?: string | null;
		work_phone?: string | null;
		street_address1?: string | null;
		street_address2?: string | null;
		city?: string | null;
		state?: string | null;
		zip?: string | null;
		date_of_birth?: string | null;
		sex?: string | null;
		gender?: string | null;
		race?: string | null;
		ethnicity?: string | null;
	}

	export interface SearchForAddOns {
		organization_id: number;
		cpt_code?: string | null;
		treatment_group_name?: string | null;
		complaint: string;
	}

	export interface SearchForTreatment {
		type: string;
		search: string;
	}

	export interface AddBookingRequest {
		organization_id: number;
		requested_timeframe: string;
		type?: string | null;
		reason?: string | null;
		original_order_id?: number | null;
		location_id: number;
		provider_id?: number | null;
		treatment_ids?: any | null;
		treatment_group_ids?: any | null;
	}

	export interface UpdateNotificationRequest {
		status?: string;
	}

	export interface AddDependent {
		first_name: string;
		last_name: string;
		email?: string | null;
		starts_on: string;
		enrollment_status?: string | null;
		date_of_birth?: string | null;
		street_address1?: string | null;
		street_address2?: string | null;
		city?: string | null;
		state?: string | null;
		zip?: string | null;
		sex?: string | null;
		home_phone?: string | null;
	}

	export interface FindBookingRequest {
		starts_at?: string | null;
		ends_at?: string | null;
		statuses?: string | null;
		include_recent_cancellations?: number | null;
	}

	export interface AddOnOrder {
		booking_request_id: number;
		treatment_id: number;
	}

	export interface EditMe {
		first_name?: string | null;
		last_name?: string | null;
		personal_email?: string | null;
		cell_phone?: string | null;
		home_phone?: string | null;
		work_phone?: string | null;
		street_address1?: string | null;
		street_address2?: string | null;
		city?: string | null;
		state?: string | null;
		zip?: string | null;
		date_of_birth?: string | null;
		sex?: string | null;
		gender?: string | null;
		race?: string | null;
		ethnicity?: string | null;
	}

	export interface SearchForOrders {
		booking_request_id?: number | null;
	}
}
export namespace Puddleglum.Controllers.Pharmacy {
	export class PharmacyAnalyticsController {
		static async getPayments(
			type: string | number,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<{total: number; totalBillingStatements: number}>(
				`/api/pharmacy/analytics/payments/${type}`,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
		static async getPaymentsByMonth(
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<Array<{date: string; total: number}>>(
				`/api/pharmacy/analytics/monthly-payments`,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
	}
	export class PharmacyOnboardingController {
		static async getStatus(
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<{is_stripe_connected: boolean; is_profile_completed: boolean}>(
				`/api/pharmacy/onboarding`,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
	}
	export class PharmacyPriceController {
		static async index(
			request: {page: number; per_page: number; search: string},
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<PaginatedResponse<Puddleglum.Models.Price>>(
				`/api/pharmacy/price?${transformToQueryString(request)}`,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
		static async store(
			request: Puddleglum.Requests.Pharmacy.AddPrice,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.post<Puddleglum.Models.Price>(`/api/pharmacy/price`, request, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async show(
			price: string | number,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<Puddleglum.Models.Price>(`/api/pharmacy/price/${price}`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async update(
			price: string | number,
			request: Puddleglum.Requests.Pharmacy.AddPrice,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.put<Puddleglum.Models.Price>(`/api/pharmacy/price/${price}`, request, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async destroy(
			price: string | number,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.delete<{success: boolean}>(`/api/pharmacy/price/${price}`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async processCsv(
			request: Puddleglum.Requests.Pharmacy.ProcessNdcPricesCsv,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.post<{message: string}>(`/api/pharmacy/price/csv`, request, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
	}
	export class PharmacyUserController {
		static async index(
			request: Puddleglum.Requests.Pharmacy.SearchUserRequest,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<Puddleglum.Models.User[]>(
				`/api/pharmacy/users?${transformToQueryString(request)}`,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
		static async show(
			user: string | number,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<Puddleglum.Models.User>(`/api/pharmacy/users/${user}`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
	}
	export class MyPharmacyController {
		static async index(
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<Puddleglum.Models.Pharmacy>(`/api/pharmacy/organization`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async update(
			request: Puddleglum.Requests.Pharmacy.UpdatePharmacy,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.put<Puddleglum.Models.Pharmacy>(`/api/pharmacy/organization`, request, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
	}
	export class PharmacyDrugSearchController {
		static async search(
			request: {search: string},
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<Puddleglum.Models.Drug[]>(
				`/api/pharmacy/drug/search?${transformToQueryString(request)}`,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
		static async find(
			drug: string | number,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<Puddleglum.Models.Drug>(`/api/pharmacy/drug/${drug}`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
	}
	export class PharmacyPaymentsController {
		static async all(
			request: {page: number; per_page: number; search: string},
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<PaginatedResponse<Puddleglum.Models.BillingStatement>>(
				`/api/pharmacy/payments?${transformToQueryString(request)}`,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
	}
	export class PharmacyOrdersController {
		static async index(
			request: Puddleglum.Requests.Pharmacy.SearchOrdersRequest,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<Puddleglum.Models.Order[]>(
				`/api/pharmacy/orders?${transformToQueryString(request)}`,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
		static async store(
			request: Puddleglum.Requests.Pharmacy.AddDrugOrder,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.post<Puddleglum.Models.Order>(`/api/pharmacy/orders`, request, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async show(
			order: string | number,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<Puddleglum.Models.Order>(`/api/pharmacy/orders/${order}`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async update(
			order: string | number,
			request: Puddleglum.Requests.Pharmacy.AddDrugOrder,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.put<Puddleglum.Models.Order>(`/api/pharmacy/orders/${order}`, request, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async destroy(
			order: string | number,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.delete<{success: boolean}>(`/api/pharmacy/orders/${order}`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
	}
}
export namespace Puddleglum.Controllers {
	export class UserController {
		static async me(
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<
				Puddleglum.Models.User & {
					is_impersonating: boolean;
					latitude: number;
					longitude: number;
				}
			>(`/api/user/me`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async updateMe(
			request: Puddleglum.Requests.Employee.EditMe,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.put(`/api/user/me`, request, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async changeMyPassword(
			request: Puddleglum.Requests.Auth.ChangePassword,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.post<{message: string}>(`/api/user/me/change-password`, request, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async changeTemporaryPassword(
			request: Puddleglum.Requests.Auth.UserPassword,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.post(`/api/user/me/change-temp-password`, request, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async listAdmins(
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<Puddleglum.Models.User[]>(`/api/admin/users/admins`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async index(
			request: {page: number; per_page: number; search: string},
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<PaginatedResponse<Puddleglum.Models.User>>(
				`/api/admin/users?${transformToQueryString(request)}`,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
		static async store(
			request: Puddleglum.Requests.Admin.AddUser,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.post(`/api/admin/users`, request, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async show(
			user: string | number,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get(`/api/admin/users/${user}`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async update(
			user: string | number,
			request: Puddleglum.Requests.Admin.EditUser,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.put<{message: string; user: Puddleglum.Models.User}>(
				`/api/admin/users/${user}`,
				request,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
		static async destroy(
			user: string | number,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.delete<{success: boolean}>(`/api/admin/users/${user}`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async activate(
			user: string | number,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.post<{success: boolean}>(`/api/admin/users/${user}/activate`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async unlockUser(
			user: string | number,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.post(`/api/admin/users/${user}/unlock-user`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async deleteTokens(
			user: string | number,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.delete<{success: boolean}>(`/api/admin/users/${user}/tokens`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async resetUserPassword(
			user: string | number,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.post(`/api/admin/users/${user}/reset-password`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
	}
	export class NotificationController {
		static async storeUserDevice(
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.post(`/api/store-token`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async getNotifications(
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<
				Array<{
					title: string;
					body: string;
					id: string;
					read_at?: string | null;
					created_at: string;
					type: string;
				}>
			>(`/api/employee/notifications`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async markAsRead(
			notification: string | number,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.put<{title: string; body: string; id: string; read_at: string | null}>(
				`/api/employee/notifications/${notification}`,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
		static async markAllAsRead(
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.post(`/api/employee/notifications/mark-all-as-read`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
	}
	export class SupportRequestController {
		static async index(
			request: {type: string | 'organization' | 'employer' | 'pharmacy' | 'mine' | 'all'},
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<Puddleglum.Models.SupportRequest[]>(
				`/api/support-requests?${transformToQueryString(request)}`,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
		static async store(
			request: Puddleglum.Requests.AddSupportRequest,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.post<Puddleglum.Models.SupportRequest>(`/api/support-requests`, request, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async show(
			support_request: string | number,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<Puddleglum.Models.SupportRequest>(
				`/api/support-requests/${support_request}`,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
		static async update(
			support_request: string | number,
			request: Puddleglum.Requests.AddSupportRequest,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.put<Puddleglum.Models.SupportRequest>(
				`/api/support-requests/${support_request}`,
				request,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
		static async destroy(
			support_request: string | number,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.delete<{success: boolean}>(`/api/support-requests/${support_request}`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async closeRequest(
			id: string | number,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.post<Puddleglum.Models.SupportRequest>(
				`/api/admin/support-requests/close/${id}`,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
		static async updateAssignees(
			request: Puddleglum.Requests.Admin.AddSupportRequestAssignment,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.post<Puddleglum.Models.SupportRequest>(
				`/api/admin/support-requests/update-assignees`,
				request,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
		static async updatePriority(
			request: Puddleglum.Requests.AddSupportRequest,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.post<Puddleglum.Models.SupportRequest>(
				`/api/admin/support-requests/update-priority`,
				request,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
	}
	export class SupportRequestAssignmentController {
		static async index(
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get(`/api/admin/support-request-assigment`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async store(
			request: Puddleglum.Requests.Admin.AddSupportRequestAssignment,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.post(`/api/admin/support-request-assigment`, request, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async show(
			support_request_assigment: string | number,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get(`/api/admin/support-request-assigment/${support_request_assigment}`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async update(
			support_request_assigment: string | number,
			request: Puddleglum.Requests.Admin.AddSupportRequestAssignment,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.put(
				`/api/admin/support-request-assigment/${support_request_assigment}`,
				request,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
		static async destroy(
			support_request_assigment: string | number,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.delete(
				`/api/admin/support-request-assigment/${support_request_assigment}`,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
	}
	export class UploadController {
		static async acknowledgeUpload(
			request: Puddleglum.Requests.AcknowledgeUpload,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.post<string>(`/api/upload/acknowledge`, request, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
	}
	export class LocationDistanceController {
		static async getLocationsByDistance(
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<Array<Puddleglum.Models.Location & {distance: number}>>(
				`/api/employee/treatment/search/locations`,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
	}
}
export namespace Puddleglum.Controllers.Auth {
	export class ForgotPasswordController {
		static async forgotPassword(
			request: Puddleglum.Requests.Auth.UserForgotPassword,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.post<{message: string}>(`/api/forgot-password`, request, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async resetPassword(
			request: Puddleglum.Requests.Auth.UserResetPassword,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.post<{message: string}>(`/api/reset-password`, request, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
	}
	export class LoginController {
		static async login(
			request: Puddleglum.Requests.Auth.UserLogin,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.post<{
				message: string;
				user: Puddleglum.Models.User;
				recipients: string[];
			}>(`/api/login`, request, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async verifyLogin(
			user: string | number,
			request: {code: string; remember?: number | null},
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.post<{
				message: string;
				user: Puddleglum.Models.User;
				remember_device_token: string | null;
			}>(`/api/verify-login/${user}`, request, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async verifyMobileLogin(
			user: string | number,
			request: {code: string; device_name: string},
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.post<{message: string}>(`/api/verify-mobile-login/${user}`, request, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async logout(
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<{message: string}>(`/api/auth/logout`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
	}
	export class MobileConnectorController {
		static async getTemporaryAppUrl(
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get(`/api/mobile-connector/app-url`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
	}
	export class RegisterController {
		static async lookupEmailAndSendVerification(
			request: Puddleglum.Requests.Auth.UserLookupEmail,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.post<{message: string}>(`/api/register/lookup-email`, request, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async verifyCode(
			request: Puddleglum.Requests.Auth.UserVerifyEmail,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.post<{message: string; user: Puddleglum.Models.User}>(
				`/api/register/verify-code`,
				request,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
		static async acceptTerms(
			request: {sms_consent: boolean},
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.post(`/api/register/accept-terms`, request, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async register(
			request: Puddleglum.Requests.Auth.UserRegister,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.post<Puddleglum.Models.User>(`/api/register`, request, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
	}
}
export namespace Puddleglum.Controllers.Employer {
	export class RosterController {
		static async resendEmployeeInvitation(
			user: string | number,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.post(`/api/employee/resend-invitation/${user}`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async getEmployees(
			request: {page: number; per_page: number; search: string},
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<PaginatedResponse<Puddleglum.Models.User>>(
				`/api/employer/roster?${transformToQueryString(request)}`,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
		static async addEmployee(
			request: Puddleglum.Requests.Employer.AddEmployee,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.post<{message: string; user: Puddleglum.Models.User}>(
				`/api/employer/roster/employee`,
				request,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
		static async editEmployee(
			user: string | number,
			request: Puddleglum.Requests.Employer.EditEmployee,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.put<{message: string; user: Puddleglum.Models.User}>(
				`/api/employer/roster/employee/${user}`,
				request,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
		static async deleteEmployee(
			user: string | number,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.delete<{message: string}>(`/api/employer/roster/employee/${user}`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async processCsv(
			request: Puddleglum.Requests.Employer.ProcessCsv,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.post<{message: string}>(`/api/employer/roster/csv`, request, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
	}
	export class EmployerOnboardingController {
		static async getStatus(
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<{
				is_stripe_connected: boolean;
				are_employees_added: boolean;
				is_profile_completed: boolean;
			}>(`/api/employer/onboarding`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
	}
	export class EmployerPayoutsController {
		static async all(
			request: {page: number; per_page: number; start_date: string; end_date: string},
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<PaginatedResponse<Puddleglum.Models.BillingStatement>>(
				`/api/employer/payouts?${transformToQueryString(request)}`,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
		static async downloadProviderReport(
			request: {start_date: string; end_date: string},
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get(
				`/api/employer/provider-payout-report?${transformToQueryString(request)}`,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
	}
	export class MyEmployerController {
		static async index(
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<Puddleglum.Models.Employer>(`/api/employer/settings`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async update(
			request: Puddleglum.Requests.Employer.UpdateEmployer,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.put<Puddleglum.Models.Employer>(`/api/employer/settings`, request, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
	}
	export class EmployerAnalyticsController {
		static async stats(
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<{
				employeeCount: number;
				employeeUsedCount: number;
				employeeUtilization: number;
				payoutCount: number;
			}>(`/api/employer/analytics/stats`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async getPayoutsByType(
			type: string | number,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<{total: number; totalBillingStatements: number}>(
				`/api/employer/analytics/payouts/${type}`,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
		static async getPayoutsByMonth(
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<Array<{date: string; total: number}>>(
				`/api/employer/analytics/monthly-payouts`,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
	}
}
export namespace Puddleglum.Controllers.Providers {
	export class ProviderTreatmentSearchController {
		static async search(
			request: {search: string},
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<Puddleglum.Models.Treatment[]>(
				`/api/treatment/search?${transformToQueryString(request)}`,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
	}
	export class ProviderOnboardingController {
		static async getStatus(
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<{
				is_stripe_connected: boolean;
				are_providers_added: boolean;
				are_locations_added: boolean;
				is_profile_completed: boolean;
			}>(`/api/provider/onboarding`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
	}
	export class MyOrganizationController {
		static async index(
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<Puddleglum.Models.Organization>(`/api/provider/organization`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async update(
			request: Puddleglum.Requests.Provider.UpdateOrganization,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.put<Puddleglum.Models.Organization>(
				`/api/provider/organization`,
				request,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
	}
	export class LocationController {
		static async index(
			request: {page: number; per_page: number; search: string},
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<PaginatedResponse<Puddleglum.Models.Location>>(
				`/api/provider/location?${transformToQueryString(request)}`,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
		static async store(
			request: Puddleglum.Requests.Provider.AddLocation,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.post<Puddleglum.Models.Location>(`/api/provider/location`, request, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async show(
			location: string | number,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<Puddleglum.Models.Location>(`/api/provider/location/${location}`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async update(
			location: string | number,
			request: Puddleglum.Requests.Provider.AddLocation,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.put<Puddleglum.Models.Location>(
				`/api/provider/location/${location}`,
				request,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
		static async destroy(
			location: string | number,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.delete<{success: boolean}>(`/api/provider/location/${location}`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
	}
	export class ProviderPaymentsController {
		static async all(
			request: {page: number; per_page: number; search: string},
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<PaginatedResponse<Puddleglum.Models.BillingStatement>>(
				`/api/provider/payments?${transformToQueryString(request)}`,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
	}
	export class PriceController {
		static async index(
			request: {page: number; per_page: number; search: string},
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<PaginatedResponse<Puddleglum.Models.Price>>(
				`/api/provider/price?${transformToQueryString(request)}`,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
		static async store(
			request: Puddleglum.Requests.Provider.AddPrice,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.post<Puddleglum.Models.Price>(`/api/provider/price`, request, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async show(
			price: string | number,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<Puddleglum.Models.Price>(`/api/provider/price/${price}`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async update(
			price: string | number,
			request: Puddleglum.Requests.Provider.AddPrice,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.put<Puddleglum.Models.Price>(`/api/provider/price/${price}`, request, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async destroy(
			price: string | number,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.delete<{success: boolean}>(`/api/provider/price/${price}`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async processCsv(
			request: Puddleglum.Requests.Provider.ProcessPricesCsv,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.post<{message: string}>(`/api/provider/price/csv`, request, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
	}
	export class ProviderBookingController {
		static async index(
			request: Puddleglum.Requests.Provider.SearchBookingRequests,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<Puddleglum.Models.BookingRequest[]>(
				`/api/provider-booking-request?${transformToQueryString(request)}`,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
		static async store(
			request: Puddleglum.Requests.Provider.AddOrUpdateBookingRequest,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.post<Puddleglum.Models.BookingRequest>(
				`/api/provider-booking-request`,
				request,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
		static async update(
			provider_booking_request: string | number,
			request: Puddleglum.Requests.Provider.AddOrUpdateBookingRequest,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.put<Puddleglum.Models.BookingRequest>(
				`/api/provider-booking-request/${provider_booking_request}`,
				request,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
		static async getLocationProviders(
			location_id: string | number,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<Puddleglum.Models.Provider[]>(
				`/api/provider/location-providers/${location_id}`,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
	}
	export class OrganizationProviderController {
		static async processCsv(
			request: Puddleglum.Requests.Provider.ProcessProviderCsv,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.post<{message: string}>(`/api/provider/roster/csv`, request, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async index(
			request: {page: number; per_page: number; search: string},
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<PaginatedResponse<Puddleglum.Models.Provider>>(
				`/api/provider/provider?${transformToQueryString(request)}`,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
		static async store(
			request: Puddleglum.Requests.Provider.AddProvider,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.post<Puddleglum.Models.Provider>(`/api/provider/provider`, request, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async show(
			provider: string | number,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<Puddleglum.Models.Provider>(`/api/provider/provider/${provider}`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async update(
			provider: string | number,
			request: Puddleglum.Requests.Provider.AddProvider,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.put<Puddleglum.Models.Provider>(
				`/api/provider/provider/${provider}`,
				request,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
		static async destroy(
			provider: string | number,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.delete<{success: boolean}>(`/api/provider/provider/${provider}`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async specialties(
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<Puddleglum.Models.Specialty[]>(`/api/provider/specialties`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
	}
	export class ProviderUserController {
		static async index(
			request: Puddleglum.Requests.Provider.SearchUserRequest,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<Puddleglum.Models.User[]>(
				`/api/provider/users?${transformToQueryString(request)}`,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
		static async show(
			user: string | number,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<Puddleglum.Models.User>(`/api/provider/users/${user}`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async setMrn(
			user: string | number,
			request: {mrn: string},
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.post<Puddleglum.Models.User>(`/api/providers/users/${user}/mrn`, request, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
	}
	export class OrdersController {
		static async index(
			request: Puddleglum.Requests.Provider.SearchOrdersRequest,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<Puddleglum.Models.Order[]>(
				`/api/provider/orders?${transformToQueryString(request)}`,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
		static async store(
			request: Puddleglum.Requests.Provider.AddOrder,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.post<Puddleglum.Models.Order>(`/api/provider/orders`, request, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async show(
			order: string | number,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<Puddleglum.Models.Order>(`/api/provider/orders/${order}`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async update(
			order: string | number,
			request: Puddleglum.Requests.Provider.AddOrder,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.put<Puddleglum.Models.Order>(`/api/provider/orders/${order}`, request, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async destroy(
			order: string | number,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.delete<{success: boolean}>(`/api/provider/orders/${order}`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async getServiceItemsFromEhr(
			user: string | number,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<{
				patient: Record<'id' | 'name' | 'mrn' | 'dob', string>;
				charges: Array<{
					id: string;
					code: string;
					code_type: string;
					description: string;
					service_at: string;
					treatment: Puddleglum.Models.Treatment;
				}>;
			}>(`/api/provider/ehr-service-items/${user}`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
	}
	export class ProviderAnalyticsController {
		static async getPayments(
			type: string | number,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<{total: number; totalBillingStatements: number}>(
				`/api/provider/analytics/payments/${type}`,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
		static async getPaymentsByMonth(
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<Array<{date: string; total: number}>>(
				`/api/provider/analytics/monthly-payments`,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
	}
}
export namespace Puddleglum.Controllers.Admin {
	export class AdminController {
		static async impersonate(
			user: string | number,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get(`/api/admin/impersonate/${user}`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async impersonateEmployer(
			employer: string | number,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.post(`/api/admin/impersonate-employer/${employer}`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async impersonationLogOut(
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get(`/api/admin/impersonate`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async impersonateOrganization(
			organization: string | number,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.post(`/api/admin/impersonate-org/${organization}`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async impersonatePharmacy(
			pharmacy: string | number,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.post(`/api/admin/impersonate-pharmacy/${pharmacy}`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async getAdminUsers(
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get(`/api/admin/admin-users`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
	}
	export class PermissionController {
		static async index(
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<Puddleglum.Models.Permission[]>(`/api/admin/permission`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async store(
			request: Puddleglum.Requests.Admin.AddPermission,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.post<Puddleglum.Models.Permission>(`/api/admin/permission`, request, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async show(
			permission: string | number,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<Puddleglum.Models.Permission>(`/api/admin/permission/${permission}`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async update(
			permission: string | number,
			request: Puddleglum.Requests.Admin.AddPermission,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.put<Puddleglum.Models.Permission>(
				`/api/admin/permission/${permission}`,
				request,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
		static async destroy(
			permission: string | number,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.delete<{success: boolean}>(`/api/admin/permission/${permission}`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
	}
	export class HomeController {
		static async stats(
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<{
				organization_count: number;
				employer_count: number;
				provider_count: number;
				user_count: number;
			}>(`/api/admin/stats`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
	}
	export class TreatmentPriceController {
		static async index(
			request: {
				page: number;
				per_page: number;
				treatment_id: number;
				treatment_group_id: number;
				drug_id: number;
			},
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<PaginatedResponse<Puddleglum.Models.Price>>(
				`/api/admin/price?${transformToQueryString(request)}`,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
		static async store(
			request: Puddleglum.Requests.Admin.AddPrice,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.post<Puddleglum.Models.Price>(`/api/admin/price`, request, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async update(
			price: string | number,
			request: Puddleglum.Requests.Admin.UpdatePrice,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.put<Puddleglum.Models.Price>(`/api/admin/price/${price}`, request, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async destroy(
			price: string | number,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.delete<{success: boolean}>(`/api/admin/price/${price}`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async processCsv(
			request: Puddleglum.Requests.Admin.ProcessChargeMasterPricesCsv,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.post<{message: string}>(`/api/admin/treatments/csv`, request, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
	}
	export class TreatmentController {
		static async index(
			request: {page: number; per_page: number; search: string},
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<PaginatedResponse<Puddleglum.Models.Treatment>>(
				`/api/admin/treatment?${transformToQueryString(request)}`,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
		static async store(
			request: Puddleglum.Requests.Admin.AddTreatment,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.post<Puddleglum.Models.Treatment>(`/api/admin/treatment`, request, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async show(
			treatment: string | number,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<Puddleglum.Models.Treatment>(`/api/admin/treatment/${treatment}`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async update(
			treatment: string | number,
			request: Puddleglum.Requests.Admin.AddTreatment,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.put<Puddleglum.Models.Treatment>(
				`/api/admin/treatment/${treatment}`,
				request,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
		static async destroy(
			treatment: string | number,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.delete<{success: boolean}>(`/api/admin/treatment/${treatment}`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async processCsv(
			request: Puddleglum.Requests.Admin.ProcessHcpcsPricesCsv,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.post<{message: string}>(`/api/admin/prices/csv`, request, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
	}
	export class OrganizationController {
		static async index(
			request: {page: number; per_page: number; search: string},
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<PaginatedResponse<Puddleglum.Models.Organization>>(
				`/api/admin/organization?${transformToQueryString(request)}`,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
		static async store(
			request: Puddleglum.Requests.Admin.AddOrganization,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.post<Puddleglum.Models.Organization>(`/api/admin/organization`, request, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async show(
			organization: string | number,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<Puddleglum.Models.Organization>(
				`/api/admin/organization/${organization}`,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
		static async update(
			organization: string | number,
			request: Puddleglum.Requests.Admin.AddOrganization,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.put<Puddleglum.Models.Organization>(
				`/api/admin/organization/${organization}`,
				request,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
		static async destroy(
			organization: string | number,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.delete<{success: boolean}>(`/api/admin/organization/${organization}`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
	}
	export class TreatmentGroupController {
		static async index(
			request: {page: number; per_page: number; search: string},
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<PaginatedResponse<Puddleglum.Models.TreatmentGroup>>(
				`/api/admin/treatment-group?${transformToQueryString(request)}`,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
		static async store(
			request: Puddleglum.Requests.Admin.AddTreatmentGroup,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.post<Puddleglum.Models.TreatmentGroup>(
				`/api/admin/treatment-group`,
				request,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
		static async show(
			treatment_group: string | number,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<Puddleglum.Models.TreatmentGroup>(
				`/api/admin/treatment-group/${treatment_group}`,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
		static async update(
			treatment_group: string | number,
			request: Puddleglum.Requests.Admin.AddTreatmentGroup,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.put<Puddleglum.Models.TreatmentGroup>(
				`/api/admin/treatment-group/${treatment_group}`,
				request,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
		static async destroy(
			treatment_group: string | number,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.delete<{success: boolean}>(
				`/api/admin/treatment-group/${treatment_group}`,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
	}
	export class SystemJobsController {
		static async index(
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<
				Array<{
					name: string;
					status: string;
					last_started_at: string;
					last_finished_at: string;
				}>
			>(`/api/admin/system-jobs`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async startJob(
			request: Puddleglum.Requests.Admin.StartJobRequest,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.post<{status: string}>(`/api/admin/system-jobs`, request, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
	}
	export class EmployerController {
		static async index(
			request: {page: number; per_page: number; search: string},
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<PaginatedResponse<Puddleglum.Models.Employer>>(
				`/api/admin/employer?${transformToQueryString(request)}`,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
		static async store(
			request: Puddleglum.Requests.Admin.AddEmployer,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.post<Puddleglum.Models.Employer>(`/api/admin/employer`, request, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async show(
			employer: string | number,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<Puddleglum.Models.Employer>(`/api/admin/employer/${employer}`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async update(
			employer: string | number,
			request: Puddleglum.Requests.Admin.AddEmployer,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.put<Puddleglum.Models.Employer>(
				`/api/admin/employer/${employer}`,
				request,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
		static async destroy(
			employer: string | number,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.delete<{success: boolean}>(`/api/admin/employer/${employer}`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
	}
	export class UserRoleController {
		static async index(
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<Puddleglum.Models.UserRole[]>(`/api/admin/user-roles`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async store(
			request: Puddleglum.Requests.Admin.AddUserRole,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.post<Puddleglum.Models.UserRole>(`/api/admin/user-roles`, request, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async show(
			user_role: string | number,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<Puddleglum.Models.UserRole>(`/api/admin/user-roles/${user_role}`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async update(
			user_role: string | number,
			request: Puddleglum.Requests.Admin.AddUserRole,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.put<Puddleglum.Models.UserRole>(
				`/api/admin/user-roles/${user_role}`,
				request,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
		static async destroy(
			user_role: string | number,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.delete<{success: boolean}>(`/api/admin/user-roles/${user_role}`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
	}
	export class AdminPaymentsController {
		static async all(
			request: {page: number; per_page: number; search: string},
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<PaginatedResponse<Puddleglum.Models.BillingStatement>>(
				`/api/admin/payments?${transformToQueryString(request)}`,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
		static async refund(
			billingStatement: string | number,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.post<Puddleglum.Models.BillingStatement>(
				`/api/admin/payments/${billingStatement}/refund`,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
	}
	export class SupportRequestResponseController {
		static async index(
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get(`/api/admin/support-request-response`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async store(
			request: Puddleglum.Requests.Admin.AddSupportRequestResponse,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.post(`/api/admin/support-request-response`, request, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async show(
			support_request_response: string | number,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get(`/api/admin/support-request-response/${support_request_response}`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async update(
			support_request_response: string | number,
			request: Puddleglum.Requests.Admin.AddSupportRequestResponse,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.put(
				`/api/admin/support-request-response/${support_request_response}`,
				request,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
		static async destroy(
			support_request_response: string | number,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.delete(`/api/admin/support-request-response/${support_request_response}`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
	}
	export class PlanController {
		static async index(
			request: {page: number; per_page: number; search: string},
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<PaginatedResponse<Puddleglum.Models.Plan>>(
				`/api/admin/plan?${transformToQueryString(request)}`,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
		static async store(
			request: Puddleglum.Requests.Admin.AddPlan,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.post<Puddleglum.Models.Plan>(`/api/admin/plan`, request, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async show(
			plan: string | number,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<Puddleglum.Models.Plan>(`/api/admin/plan/${plan}`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async update(
			plan: string | number,
			request: Puddleglum.Requests.Admin.AddPlan,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.put<Puddleglum.Models.Plan>(`/api/admin/plan/${plan}`, request, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async destroy(
			plan: string | number,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.delete<{success: boolean}>(`/api/admin/plan/${plan}`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async setCoverageBucketRate(
			plan: string | number,
			coverageBucket: string | number,
			request: {covered_pct: string},
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.post<{success: boolean}>(
				`/api/admin/plan/${plan}/coverage-bucket/${coverageBucket}/set-rate`,
				request,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
		static async attachCoverageBucket(
			plan: string | number,
			coverageBucket: string | number,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.post<{success: boolean}>(
				`/api/admin/plan/${plan}/coverage-bucket/${coverageBucket}`,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
		static async detachCoverageBucket(
			plan: string | number,
			coverageBucket: string | number,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.delete<{success: boolean}>(
				`/api/admin/plan/${plan}/coverage-bucket/${coverageBucket}`,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
	}
	export class PharmacyController {
		static async index(
			request: {page: number; per_page: number; search: string},
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<PaginatedResponse<Puddleglum.Models.Pharmacy>>(
				`/api/admin/pharmacy?${transformToQueryString(request)}`,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
		static async store(
			request: Puddleglum.Requests.Admin.AddPharmacy,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.post<Puddleglum.Models.Pharmacy>(`/api/admin/pharmacy`, request, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async show(
			pharmacy: string | number,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<Puddleglum.Models.Pharmacy>(`/api/admin/pharmacy/${pharmacy}`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async update(
			pharmacy: string | number,
			request: Puddleglum.Requests.Admin.AddPharmacy,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.put<Puddleglum.Models.Pharmacy>(
				`/api/admin/pharmacy/${pharmacy}`,
				request,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
		static async destroy(
			pharmacy: string | number,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.delete<{success: boolean}>(`/api/admin/pharmacy/${pharmacy}`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
	}
	export class ProviderController {
		static async index(
			request: {page: number; per_page: number; search: string},
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<PaginatedResponse<Puddleglum.Models.Provider>>(
				`/api/admin/provider?${transformToQueryString(request)}`,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
		static async store(
			request: Puddleglum.Requests.Admin.AddProvider,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.post<Puddleglum.Models.Provider>(`/api/admin/provider`, request, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async show(
			provider: string | number,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<Puddleglum.Models.Provider>(`/api/admin/provider/${provider}`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async update(
			provider: string | number,
			request: Puddleglum.Requests.Admin.AddProvider,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.put<Puddleglum.Models.Provider>(
				`/api/admin/provider/${provider}`,
				request,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
		static async destroy(
			provider: string | number,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.delete<{success: boolean}>(`/api/admin/provider/${provider}`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
	}
	export class AuditController {
		static async index(
			request: {page: number; per_page: number},
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<PaginatedResponse<Puddleglum.Models.Audit>>(
				`/api/admin/audit?${transformToQueryString(request)}`,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
	}
	export class AdminAnalyticsController {
		static async getPayments(
			type: string | number,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<{total: number; totalBillingStatements: number}>(
				`/api/admin/analytics/payments/${type}`,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
		static async getPaymentsByMonth(
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<Array<{date: string; total: number}>>(
				`/api/admin/analytics/monthly-payments`,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
	}
	export class DrugController {
		static async index(
			request: {page: number; per_page: number; search: string},
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<PaginatedResponse<Puddleglum.Models.Drug>>(
				`/api/admin/drug?${transformToQueryString(request)}`,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
		static async show(
			drug: string | number,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<Puddleglum.Models.Drug>(`/api/admin/drug/${drug}`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async destroy(
			drug: string | number,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.delete<{success: boolean}>(`/api/admin/drug/${drug}`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
	}
	export class SpecialtyController {
		static async index(
			request: {page: number; per_page: number; search: string},
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<PaginatedResponse<Puddleglum.Models.Specialty>>(
				`/api/admin/specialty?${transformToQueryString(request)}`,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
		static async store(
			request: Puddleglum.Requests.Admin.AddSpecialty,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.post<Puddleglum.Models.Specialty>(`/api/admin/specialty`, request, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async show(
			specialty: string | number,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<Puddleglum.Models.Specialty>(`/api/admin/specialty/${specialty}`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async update(
			specialty: string | number,
			request: Puddleglum.Requests.Admin.AddSpecialty,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.put<Puddleglum.Models.Specialty>(
				`/api/admin/specialty/${specialty}`,
				request,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
		static async destroy(
			specialty: string | number,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.delete<{success: boolean}>(`/api/admin/specialty/${specialty}`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
	}
	export class CoverageBucketController {
		static async index(
			request: {page: number; per_page: number; search: string},
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<PaginatedResponse<Puddleglum.Models.CoverageBucket>>(
				`/api/admin/coverage-bucket?${transformToQueryString(request)}`,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
		static async store(
			request: Puddleglum.Requests.Admin.AddCoverageBucket,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.post<Puddleglum.Models.CoverageBucket>(
				`/api/admin/coverage-bucket`,
				request,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
		static async show(
			coverage_bucket: string | number,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<Puddleglum.Models.CoverageBucket>(
				`/api/admin/coverage-bucket/${coverage_bucket}`,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
		static async update(
			coverage_bucket: string | number,
			request: Puddleglum.Requests.Admin.AddCoverageBucket,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.put<Puddleglum.Models.CoverageBucket>(
				`/api/admin/coverage-bucket/${coverage_bucket}`,
				request,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
		static async destroy(
			coverage_bucket: string | number,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.delete<{success: boolean}>(
				`/api/admin/coverage-bucket/${coverage_bucket}`,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
	}
	export class AdminBookingController {
		static async index(
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<Puddleglum.Models.BookingRequest[]>(`/api/admin/booking-requests`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async approve(
			bookingRequest: string | number,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.post<Puddleglum.Models.BookingRequest>(
				`/api/admin/booking-request/${bookingRequest}/approve`,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
		static async decline(
			bookingRequest: string | number,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.post<Puddleglum.Models.BookingRequest>(
				`/api/admin/booking-request/${bookingRequest}/decline`,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
	}
}
export namespace Puddleglum.Controllers.Employee {
	export class MyProvidersController {
		static async index(
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<
				Array<
					Puddleglum.Models.Provider & {
						last_seen: string;
						booking_requests_count: number;
						price: Puddleglum.Models.Price;
					}
				>
			>(`/api/employee/providers`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
	}
	export class EmployeeTreatmentController {
		static async index(
			request: {organization_id: number; treatment_ids: number[]},
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<
				Array<
					Puddleglum.Models.Treatment & {
						notes: string;
						price: number;
						employeePortion: number;
						employerPortion: number;
					}
				>
			>(`/api/employee/treatments?${transformToQueryString(request)}`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
	}
	export class EmployeeProviderController {
		static async show(
			provider: string | number,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<Puddleglum.Models.Provider>(`/api/employee/providers/${provider}`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
	}
	export class ProcedureSearchController {
		static async index(
			request: {search: string; distance: number},
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<{
				treatments: Puddleglum.Models.Treatment[];
				treatment_groups: Puddleglum.Models.TreatmentGroup[];
			}>(`/api/employee/search/procedures?${transformToQueryString(request)}`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async show(
			id: string | number,
			request: {type: string},
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<{
				type: string;
				treatment: Puddleglum.Models.Treatment | Puddleglum.Models.TreatmentGroup;
				results: Array<{
					id: string;
					name: string;
					organization: string;
					organization_id: number;
					location_id: number;
					latitude: number;
					longitude: number;
					street_address1: string;
					street_address2: string;
					city: string;
					state: string;
					zip: string;
					distance: number;
					treatment_id?: number | null;
					treatment_group_id?: number | null;
					treatment_name: string;
					treatment_code: string;
					price: {
						employeePortion: string;
						employerPortion: string;
						notes: string;
						price: string;
					};
				}>;
			}>(`/api/employee/search/procedures/${id}?${transformToQueryString(request)}`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
	}
	export class ProviderSearchController {
		static async index(
			request: {search: string; distance: number},
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<
				Array<{
					id: string;
					name: string;
					organization: string;
					new_treatment_group_id: number;
					established_treatment_group_id: number;
					organization_id: number;
					established_price: {
						employeePortion: string;
						employerPortion: string;
						notes: string;
						price: string;
					};
					new_price: {
						employeePortion: string;
						employerPortion: string;
						notes: string;
						price: string;
					};
					photo_url: string;
					location_id: number;
					latitude: number;
					longitude: number;
					street_address1: string;
					street_address2: string;
					city: string;
					state: string;
					zip: string;
					specialties: Array<{id: string; name: string}>;
					locations: Array<{
						id: string;
						name: string;
						street_address1: string;
						street_address2: string;
						city: string;
						state: string;
						zip: string;
					}>;
					distance: number;
				}>
			>(`/api/employee/search/providers?${transformToQueryString(request)}`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
	}
	export class EmployeeTreatmentGroupController {
		static async index(
			request: {organization_id: number; treatment_group_ids: number[]},
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<
				Array<
					Puddleglum.Models.TreatmentGroup & {
						notes: string;
						price: number;
						employeePortion: number;
						employerPortion: number;
					}
				>
			>(`/api/employee/treatment-groups?${transformToQueryString(request)}`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
	}
	export class EmployeeLocationController {
		static async show(
			location: string | number,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<Puddleglum.Models.Location>(`/api/employee/locations/${location}`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
	}
	export class MyOrdersController {
		static async index(
			request: Puddleglum.Requests.Employee.SearchForOrders,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<Puddleglum.Models.Order[]>(
				`/api/employee/orders?${transformToQueryString(request)}`,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
		static async store(
			request: Puddleglum.Requests.Employee.AddOnOrder,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.post(`/api/employee/orders`, request, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
	}
	export class EmployeeOrganizationController {
		static async show(
			organization: string | number,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<Puddleglum.Models.Organization>(
				`/api/employee/organizations/${organization}`,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
	}
	export class MyDependentsController {
		static async index(
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<Puddleglum.Models.User[]>(`/api/dependents`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async store(
			request: Puddleglum.Requests.Employee.EditMyDependent,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.post<Puddleglum.Models.User>(`/api/dependents`, request, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async show(
			dependent: string | number,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<Puddleglum.Models.User>(`/api/dependents/${dependent}`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async update(
			dependent: string | number,
			request: Puddleglum.Requests.Employee.EditMyDependent,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.put<Puddleglum.Models.User>(`/api/dependents/${dependent}`, request, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async destroy(
			dependent: string | number,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.delete<{success: boolean}>(`/api/dependents/${dependent}`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
	}
	export class SpecialtySearchController {
		static async index(
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<Puddleglum.Models.Specialty[]>(`/api/employee/specialties`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
	}
	export class PaymentController {
		static async getBillingStatements(
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<Puddleglum.Models.BillingStatement[]>(
				`/api/employee/billing-statement`,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
		static async getBillingStatement(
			billingStatement: string | number,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<{
				billing_statement: Puddleglum.Models.BillingStatement;
				stripe_client_secret: string;
			}>(`/api/employee/billing-statement/${billingStatement}`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async getBillingStatementByBookingRequest(
			bookingRequest: string | number,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<{
				billing_statement: Puddleglum.Models.BillingStatement;
				stripe_client_secret: string;
			}>(`/api/employee/booking-request/${bookingRequest}/billing-statement`, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async getRecentPayments(
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<Puddleglum.Models.BillingStatement[]>(
				`/api/employee/recent-payments`,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
	}
	export class BookingController {
		static async index(
			request: Puddleglum.Requests.Employee.FindBookingRequest,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<Puddleglum.Models.BookingRequest[]>(
				`/api/booking-request?${transformToQueryString(request)}`,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
		static async store(
			request: Puddleglum.Requests.Employee.AddBookingRequest,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.post<Puddleglum.Models.BookingRequest>(`/api/booking-request`, request, {
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			});
		}
		static async show(
			booking_request: string | number,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.get<{bookingRequest: Puddleglum.Models.BookingRequest}>(
				`/api/booking-request/${booking_request}`,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
		static async update(
			booking_request: string | number,
			request: Puddleglum.Requests.Employee.AddBookingRequest,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.put<Puddleglum.Models.BookingRequest>(
				`/api/booking-request/${booking_request}`,
				request,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
		static async destroy(
			booking_request: string | number,
			validationOnly: boolean = false,
			fieldToValidate: string = '',
			config: AxiosRequestConfig = {},
		) {
			return axios.delete<Puddleglum.Models.BookingRequest>(
				`/api/booking-request/${booking_request}`,
				{
					headers: {
						Precognition: validationOnly,
						...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
					},
					...config,
				},
			);
		}
	}
}
