import React from 'react';
import {FormControl, FormErrorMessage, FormLabel, Textarea} from '@chakra-ui/react';
import {PuddleBag} from '../../hooks/usePuddleForm';

interface PuddleTextareaProps<TModel, TRequest> {
	label: string;
	fieldName: keyof TRequest;
	form: PuddleBag<TModel, TRequest>;
	isRequired?: boolean;
	placeholder?: string;
}

const PuddleTextarea = <TModel, TRequest>({
	label,
	fieldName,
	form,
	isRequired = false,
	placeholder,
}: PuddleTextareaProps<TModel, TRequest>) => (
	<FormControl isInvalid={form.fieldHasError(fieldName)} isRequired={isRequired}>
		<FormLabel color='gray.500'>{label}</FormLabel>
		<Textarea
			bgColor={isRequired && !form.model ? 'yellow.100' : 'white'}
			value={
				form.model && form.model[fieldName as unknown as keyof TModel]
					? (form.model[fieldName as unknown as keyof TModel] as string)
					: ''
			}
			onChange={(e) => form.updateField(fieldName, e.target.value)}
			onBlur={(e) => form.validateField(fieldName, e.target.value)}
			placeholder={placeholder}
		/>
		{form.fieldHasError(fieldName) && (
			<FormErrorMessage fontSize='xs'>{form.getFieldError(fieldName)}</FormErrorMessage>
		)}
	</FormControl>
);

export default PuddleTextarea;
