import React from 'react';
import {
	FormControl,
	FormErrorMessage,
	FormLabel,
	Icon,
	Select,
	SelectProps,
	Tooltip,
} from '@chakra-ui/react';
import {MdInfo} from 'react-icons/md';
import {PuddleBag} from '../../hooks/usePuddleForm';

interface PuddleSelectProps<TModel, TRequest> {
	label: string;
	options: {label: string; value: string | number}[];
	fieldName: keyof TRequest;
	helperText?: string;
	form: PuddleBag<TModel, TRequest>;
	isRequired?: boolean;
	selectProps?: SelectProps;
	disabled?: boolean;
	placeholder?: string;
}

const PuddleSelect = <TModel, TRequest>({
	label,
	fieldName,
	helperText,
	options,
	form,
	isRequired = false,
	selectProps = {},
	disabled,
	placeholder = 'Select an option',
}: PuddleSelectProps<TModel, TRequest>) => {
	const value =
		form.model && form.model[fieldName as unknown as keyof TModel]
			? (form.model[fieldName as unknown as keyof TModel] as string)
			: '';

	return (
		<FormControl isInvalid={form.fieldHasError(fieldName)} isRequired={isRequired}>
			<FormLabel color='gray.500'>
				{label}
				{helperText && (
					<Tooltip label={helperText}>
						<span>
							<Icon as={MdInfo} ml={2} />
						</span>
					</Tooltip>
				)}
			</FormLabel>
			<Select
				disabled={disabled}
				bgColor={isRequired && !value ? 'yellow.100' : 'white'}
				value={value}
				onChange={(e) => {
					form.updateField(fieldName, e.target.value);
				}}
				onBlur={(e) => form.validateField(fieldName, e.target.value)}
				{...selectProps}
				placeholder={placeholder}
			>
				{options.map((option) => (
					<option key={option.value} value={option.value}>
						{option.label}
					</option>
				))}
			</Select>
			{form.fieldHasError(fieldName) && (
				<FormErrorMessage fontSize='xs'>{form.getFieldError(fieldName)}</FormErrorMessage>
			)}
		</FormControl>
	);
};
export default PuddleSelect;
